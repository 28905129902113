<template>
  <div class="container mt-5">
    <h1>Order Payment Failed</h1>
    <p class="text-danger">
      You payment for order has not been successfully
    </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentFailed",
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.getOrder();
  },
  computed: {
    isPaid() {
      return this.order.status.name === 'paid';
    },
    isPending() {
      return this.order.status.name === 'pending';
    }
  },
  methods: {
    getOrder() {
      this.isLoading = true;
      axios.get('orders/' + this.$route.params.id).then((response) => {
        this.order = response.data;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>